import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { USER_TYPE_MEMBER, USER_TYPE_TENANT } from 'gigshq/constants/user-types';

export default Component.extend(Localizable(translations), {
  userTypeTenant: USER_TYPE_TENANT,
  userTypeMember: USER_TYPE_MEMBER,

  signupMenuIsOpen: computed(function() {
    return false;
  }),
});
