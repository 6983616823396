import { inject as service } from '@ember/service';
import WebEventsRoute from '../base-route';
import RSVP from 'rsvp';
import moment from 'moment';

export default WebEventsRoute.extend({
  geolocationFetcher: service('fetchers/geolocation-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  beforeModel() {
    this.controllerFor('web.events.index.list').set('fetchForWebEventsList', true);
  },

  model(_, transition) {
    const fromDate = transition.to.queryParams.from;
    const toDate = transition.to.queryParams.to;
    const city = transition.to.queryParams.city;
    const page = parseInt(transition.to.queryParams.page, 10) || 1;
    this.controllerFor('web.events.index').set('page', page);

    const functionsToRun = [
      this.geolocationFetcher.fetch(),
      this._loadGoogleMapsScript(),
    ];

    const model = RSVP.all(functionsToRun)
      .then(([userLocation, _]) => {
        const tempStartedAfter = fromDate ? fromDate : moment().startOf('day').format('YYYY-MM-DD');

        return {
          userLocation,
          tempStartedAfter,
          toDate,
          city,
          page,
        };
      });

    return model;
  },
});
