import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

import query from 'gigshq/graphql/queries/venue-emails';

export default Component.extend(Localizable(translations), {
  apollo: service(),
  i18n: service(),

  propTypes: {
    venueCategories: T.array.isRequired,
  },

  didReceiveAttrs() {
    this.venueCategories.push({
      id: -999,
      name: 'uncategorized'
    });
    this.venueCategories = this.venueCategories.map((entry) => {
      entry.translatedName = this.i18n.t('general.venue_categories.' + entry.name).string;
      entry.selected = true;
      return entry;
    });
  },

  sortedCategories: computed('venueCategories.[]', function() {
    return this.venueCategories.sort((catA, catB) => {
      if (catA.id === -999)
        return 1;

      return catA.translatedName.localeCompare(catB.translatedName)
    });
  }),

  actions: {
    updateSelectedCategories(categoryId, isSelected) {
      if (categoryId === 'ALL')
        this.venueCategories.forEach((entry) => Ember.set(entry, 'selected', isSelected));
      else {
        const category = this.venueCategories.find((entry) => entry.id === categoryId);
        Ember.set(category, 'selected', isSelected);
      }
    },

    getEmails() {
      const categories = this.venueCategories.filter((entry) => entry.selected)
        .map((entry) => parseInt(entry.id, 10));

      const variables = { categories };
      this.apollo.queryOperation()({query, variables}, 'viewer')
        .then((results) => {
          const emailList = results.venues.reduce((accumulator, venue) => accumulator + venue.email + ';', '');
          navigator.clipboard.writeText(emailList);

          alert(this.localTranslation('success'));
        });
    }
  }
});
