import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import moment from 'moment';

const HEX_VALIDATOR = new RegExp('^(?:[0-9a-fA-F]{3}){1,2}$');

export default Route.extend({
  eventsFetcher: service('fetchers/events-fetcher'),

  queryParams: {
    lang: { refreshModel: true }
  },

  model(params, transition) {
    if (params.lang)
      this.controllerFor('calendar').send('changeLocale', params.lang, true);

    const venue = transition.to.queryParams['venue'];
    const bgColor = transition.to.queryParams['bg'];
    const textColor = transition.to.queryParams['text'];

    const fromDate = moment().startOf('day').format('YYYY-MM-DD');

    const events = this.eventsFetcher.fetchEvents({
      forVenueIds: venue ? [venue] : null,
      page: 1,
      fromDate});

    const customBgColor = HEX_VALIDATOR.test(bgColor) ? `#${bgColor}` : null;
    const customTextColor = HEX_VALIDATOR.test(textColor) ? `#${textColor}` : null;

    return {
      venue,
      fromDate,
      events,
      customBgColor,
      customTextColor
    };
  }
});
