import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/venue-categories';

export default Route.extend({
  accountManager: service('managers/account-manager'),
  apollo: service(),
  router: service(),

  beforeModel() {
    if (!this.accountManager.isSuperUser)
      this.router.transitionTo('app.venues.index');
  },

  model() {
    return this.apollo.queryOperation()({query}, 'viewer');
  }
});
