import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { and, or } from '@ember/object/computed';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  propTypes: {
    events: T.array,
    venue: T.object,
    fromDate: T.nullable(T.string),
    toDate: T.nullable(T.string),
    userLocation: T.nullable(
      T.shape({
        latitude: T.number.isRequired,
        longitude: T.number.isRequired
      })
    ),
    city: T.string,
    totalEventCount: T.number,
    totalPageCount: T.number,
    page: T.number,
    onPageSelect: T.func,
    isCalendarPlugin: T.nullable(T.bool),
    scrollLoadMoreEvents: T.bool
  },

  eventsFetcher: service('fetchers/events-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  isLoadingEventsOrVenues: or('eventsFetcher.isLoadingEvents', 'venuesFetcher.isLoadingVenues'),
  hasDateRange: and('fromDate', 'toDate'),

  eventsByMonth: computed('events.[]', function() {
    if (this.events == null)
      return null;

    return this.events.reduce((memo, event) => {
      const eventMonth = moment(event.startedAt).tz(event.venue.timezone).format('YYYY-MM');
      if (!memo[eventMonth]) {
        memo[eventMonth] = [ event ];
      } else {
        memo[eventMonth].push(event);
      }

      return memo;
    }, {});
  }),
});
