import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';
import { PropTypes as T } from 'ember-prop-types';
import mapStyles from 'gigshq/constants/map-styles';
import getMapPin from 'gigshq/constants/map-pins';

const MAP_OPTIONS = {
  styles: mapStyles,
  backgroundColor: '#1f1f1f',
  zoom: 17,
  clickableIcons: false,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  draggable: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  keyboardShortcuts: false,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  scrollwheel: false,
  signInControl: false,
  streetViewControl: false,
  zoomControl: false
};

const MARKER_OPTIONS = {
  clickable: true,
  optimized: false
};

export default Component.extend({
  propTypes: {
    venue: T.looseShape({
      formattedAddress: T.string.isRequired
    }),
    latitude: T.number.isRequired,
    longitude: T.number.isRequired
  },

  showMapUrl: computed('venue.formattedAddress', function() {
    const address = this.get('venue.formattedAddress');

    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  }),

  didInsertElement() {
    const venueCoordinates = new window.google.maps.LatLng(
      this.latitude,
      this.longitude
    );

    this.renderMap(venueCoordinates);
    this.renderMarker(venueCoordinates);

    const centeringDelay = 100;
    const immediate = false;

    $(window).on('resize.gigs-web-venue-map', () => {
      run.throttle(
        this,
        'centerMap',
        venueCoordinates,
        centeringDelay,
        immediate
      );
    });
  },

  didRender() {
    const venueCoordinates = new window.google.maps.LatLng(
      this.latitude,
      this.longitude
    );

    this.renderMap(venueCoordinates);
    this.renderMarker(venueCoordinates);
  },

  willDestroyElement() {
    $(window).off('resize.gigs-web-venue-map');
  },

  renderMap(coordinates) {
    const element = this.$('.gigs-web-venue-map__map-container').get(0);

    const map = new window.google.maps.Map(element, {
      ...MAP_OPTIONS,
      center: coordinates
    });

    this.set('map', map);
  },

  renderMarker(coordinates) {
    const marker = new window.google.maps.Marker({
      ...MARKER_OPTIONS,
      /* eslint-disable no-magic-numbers */
      icon: {
        size: new window.google.maps.Size(45, 45),
        scaledSize: new window.google.maps.Size(45, 45),
        labelOrigin: new window.google.maps.Point(40, 30),
        url: getMapPin(this.venue.venueCategory, this.venue.hasVenueOffers ? 'offers' : null),
      },
      /* eslint-enable no-magic-numbers */
      map: this.map,
      position: coordinates
    });

    marker.addListener('click', () => {
      window.open(this.showMapUrl);
    });
  }
});
