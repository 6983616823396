import Component from '@ember/component';
import { PropTypes as T } from 'ember-prop-types';

export default Component.extend({
  propTypes: {
    size: T.string,
    white: T.boolean
  },

  getDefaultProps() {
    return {
      size: 'large'
    };
  },

  classNameBindings: ['size']
});
