import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),

  isOfferAlreadyRedeemedThisWeek: computed('offer.{redemptionPeriod,lastRedemptionDate}', function() {
    if (this.offer.redemptionPeriod === 'weekly' && this.offer.lastRedemptionDate != null) {
      const today = moment().local();
      const lastRedemptionDate = moment(this.offer.lastRedemptionDate).local();
      if (today.diff(lastRedemptionDate, 'days') < 7)
        return true;
    }

    return false;
  }),

  actions: {
    slide(obj) {
      const $slider = this.$(obj.target);
      const sliderPosition = $slider.val();

      if (sliderPosition > 99
          && window.confirm(this.localTranslation('confirm'))) {
        this.$("#gigs-slide-to-redeem__slider").hide();
        this.submit();
        this.$(".gigs-slide-to-redeem__completed").fadeIn();
      } else
        this.actions.resetSlider($slider);
    },

    resetSlider(slider) {
      setInterval(() => {
        const currValue = slider.val();

        if (currValue <= 0)
          return;

        slider.val(currValue - 1);
      }, 200);
    }
  }
});
