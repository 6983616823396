import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import $ from 'jquery';
import RSVP from 'rsvp';
import config from 'gigshq/config/environment';

export default Route.extend({
  apollo: service(),
  fastboot: service(),
  eventsFetcher: service('fetchers/events-fetcher'),
  sessionFetcher: service('fetchers/session-fetcher'),
  venuesFetcher: service('fetchers/venues-fetcher'),

  activate() {
    // map & list view always has to start at page 1 upon entry
    this.controllerFor('web.events.index').set('page', null);
    this.eventsFetcher.set('currentPage', 1);
  },

  deactivate() {
    this.eventsFetcher.set('fetchedEvents', []);
    this.venuesFetcher.set('fetchedVenues', []);
  },

  _loadGoogleMapsScript() {
    if (this.fastboot.isFastBoot)
      return;

    if (window.google && window.google.maps)
      return;

    return new RSVP.Promise((resolve, reject) => {
      $.getScript(config.Google.PLACES_SDK_SCRIPT_URL)
        .done(resolve)
        .fail(reject);
    });
  },
});
