import { computed } from '@ember/object';
import { equal, readOnly, not, and, or } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER } from 'gigshq/constants/user-types';
import moment from 'moment';

export default Service.extend({
  fastboot: service(),
  redux: service(),

  userName: null,
  lastRedemptionDate: null,

  isUserTypeArtist: computed('roles.[]', function() {
    return this.roles && this.roles.includes(USER_TYPE_ARTIST);
  }),

  isUserTypeTenant: computed('roles.[]', function() {
    return this.roles && this.roles.includes(USER_TYPE_TENANT);
  }),

  isUserTypeMember: computed('roles.[]', function() {
    return this.roles && this.roles.includes(USER_TYPE_MEMBER);
  }),

  isCompletedAccount: readOnly('completedAcount'),
  isUncompletedAccount: not('completedAcount'),
  isUncompletedArtistAccount: and('isUserTypeArtist', 'isUncompletedAccount'),
  isUncompletedTenantAccount: and('isUserTypeTenant', 'isUncompletedAccount'),

  isAlain: equal('id', '7'),
  isKevin: equal('id', '59'),
  isSuperUser: or('isAlain', 'isKevin'),

  isLoggedIn: computed('userName', function() {
    const { session } = this.redux.getState();

    if (this.userName)
      console.log(`Hello ${this.userName}`);

    return session.data;
  }),

  hasActiveMembership: equal('membershipStatus', 'active'),

  alreadyRedeemedToday: computed('lastRedemptionDate', function() {
    const today = moment().local();
    const lastRedemptionDate = moment(this.lastRedemptionDate).local();
    return today.isSame(lastRedemptionDate, 'day');
 }),

  setAccount({id, firstName, lastName, email, roles, lastRedemptionDate, member, totalManagedVenues, managedArtists}) {
    const { userRoles } = this.getProperties('roles');

    if (userRoles) {
      this.setProperties({ lastRedemptionDate });
      return;
    }

    this.set('userName', `${lastName}, ${firstName}`);

    this.setProperties({
      id,
      firstName,
      lastName,
      email,
      roles,
      membershipStatus: member?.membershipStatus,
      totalManagedVenues,
      lastRedemptionDate,
      completedAcount: this._isCompletedAccount(roles, totalManagedVenues, managedArtists)
    });
  },

  setCompletedAccount(completed) {
    this.set('completedAcount', completed);
  },

  logout() {
    this.setProperties({
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      roles: null,
      membershipStatus: null,
      lastRedemptionDate: null,
      completedAcount: false,
    });
  },

  _isCompletedAccount(roles, totalManagedVenues, managedArtists) {
    let completedAccount = false;
    if (roles) {
      if (roles.includes(USER_TYPE_TENANT))
        completedAccount = completedAccount || totalManagedVenues > 0;
      if (roles.includes(USER_TYPE_ARTIST))
        completedAccount = completedAccount || this._hasOneCompletedArtist(managedArtists);
      if (roles.includes(USER_TYPE_MEMBER))
        completedAccount = completedAccount || true;
    }

    return completedAccount;
  },

  _hasOneCompletedArtist(artists) {
    return artists.map(artist => artist.isCompleted).includes(true);
  }
});
