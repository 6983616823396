import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

// Queries
import query from 'gigshq/graphql/queries/admin-users';

export default Route.extend({
  apollo: service(),

  model() {
    const watchQuery = this.apollo.watchQueryOperation({query});

    const adminUsersSubscription = watchQuery.subscribe({
      next: result => {
        if (result.stale) {
          watchQuery.refetch();
        } else {
          const users = [...result.data.viewer.users].filter(
            ({tenant}) => tenant !== null
          );
          this.controllerFor('app.users.index').set('model', {users});
        }
      }
    });

    this.set('adminUsersSubscription', adminUsersSubscription);
  },

  deactivate() {
    this.adminUsersSubscription.unsubscribe();
    this.set('adminUsersSubscription', null);
  }
});
