import Component from '@ember/component';
import { computed } from '@ember/object';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import moment from 'moment';

export default Component.extend(Localizable(translations), {
  minDate: computed(() =>
    moment()
      .startOf('day')
      .toISOString()
  ),

  actions: {
    handleReverseBlackout(value) {
      this.setProperty('isReversedBlackout', value);
    },

    handleDatesUpdate(blackoutDates) {
      this.setProperty('blackoutDates', blackoutDates.date);
    },
  },
});
