import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import { USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER, USER_TYPE_GENERAL } from 'gigshq/constants/user-types';

export default Component.extend(Localizable(translations), {

  propTypes: {
    userType: T.oneOf([ USER_TYPE_ARTIST, USER_TYPE_TENANT, USER_TYPE_MEMBER ]).isRequired,
    menuType: T.string.isRequired,
    isOpen: T.bool.isRequired
  },

  classNameBindings: [ 'isUserTypeArtist:web-top-bar-dropdown--artist' ],

  accountManager: service('managers/account-manager'),

  isMenuTypeAbout: equal('menuType', 'about'),
  isMenuTypeSignup: equal('menuType', 'sign-up'),

  aboutUserTypes: [
    {
      type: USER_TYPE_MEMBER,
      label: 'top_bar_drop_down.members',
    },
    {
      type:USER_TYPE_TENANT,
      label: 'top_bar_drop_down.tenants',
    },
    {
      type: USER_TYPE_GENERAL,
      label: 'top_bar_drop_down.gigshq',
    }
  ],

  userTypes: [
    {
      type: USER_TYPE_MEMBER,
      label: 'top_bar_drop_down.members',
    },
    {
      type: USER_TYPE_ARTIST,
      label: 'top_bar_drop_down.artist_managers',
    },
    {
      type:USER_TYPE_TENANT,
      label: 'top_bar_drop_down.tenants',
    }
  ],

  buttonLabel: computed('userType', function() {
    const userType = this.userType;
    return this.localTranslation(`top_bar_drop_down.${userType}s`);
  }),

  actions: {
    toggleDropDown() {
      this.toggleProperty('isOpen');
    }
  }
});
