export default function getMapPin(venueCategory, version) {
  if (venueCategory?.name === 'amphitheater') {
    if (version === 'offers')
      return '/assets/images/map-pins/amphitheater/img-map-pin-offers.png';
    else if (version === 'eventless-venue')
      return '/assets/images/map-pins/amphitheater/img-map-pin-eventless-venue.png';
    else if (version === 'eventless-venue-offers')
      return '/assets/images/map-pins/amphitheater/img-map-pin-eventless-venue-offers.png';

    return '/assets/images/map-pins/amphitheater/img-map-pin.png';
  } else if (venueCategory?.name === 'brewery') {
    if (version === 'offers')
      return '/assets/images/map-pins/brewery/img-map-pin-offers.png';
    else if (version === 'eventless-venue')
      return '/assets/images/map-pins/brewery/img-map-pin-eventless-venue.png';
    else if (version === 'eventless-venue-offers')
      return '/assets/images/map-pins/brewery/img-map-pin-eventless-venue-offers.png';

    return '/assets/images/map-pins/brewery/img-map-pin.png';
  } else if (venueCategory?.name === 'festival') {
    if (version === 'offers')
      return '/assets/images/map-pins/festival/img-map-pin-offers.png';
    else if (version === 'eventless-venue')
      return '/assets/images/map-pins/festival/img-map-pin-eventless-venue.png';
    else if (version === 'eventless-venue-offers')
      return '/assets/images/map-pins/festival/img-map-pin-eventless-venue-offers.png';

    return '/assets/images/map-pins/festival/img-map-pin.png';
  } else if (venueCategory?.name === 'winery') {
    if (version === 'offers')
      return '/assets/images/map-pins/winery/img-map-pin-offers.png';
    else if (version === 'eventless-venue')
      return '/assets/images/map-pins/winery/img-map-pin-eventless-venue.png';
    else if (version === 'eventless-venue-offers')
      return '/assets/images/map-pins/winery/img-map-pin-eventless-venue-offers.png';

    return '/assets/images/map-pins/winery/img-map-pin.png';
  } else if (venueCategory?.name === 'distillery') {
    if (version === 'offers')
      return '/assets/images/map-pins/distillery/img-map-pin-offers.png';
    else if (version === 'eventless-venue')
      return '/assets/images/map-pins/distillery/img-map-pin-eventless-venue.png';
    else if (version === 'eventless-venue-offers')
      return '/assets/images/map-pins/distillery/img-map-pin-eventless-venue-offers.png';

    return '/assets/images/map-pins/distillery/img-map-pin.png';
  }

  if (version === 'offers')
    return '/assets/images/map-pins/img-map-pin-offers.png';
  else if (version === 'eventless-venue')
    return '/assets/images/map-pins/img-map-pin-eventless-venue.png';
  else if (version === 'eventless-venue-offers')
    return '/assets/images/map-pins/img-map-pin-eventless-venue-offers.png';

  return '/assets/images/map-pins/img-map-pin.png';
}
