import Service, { inject as service } from '@ember/service';
import { getDistance } from 'geolib';
import config from 'gigshq/config/environment';

// Queries
import venuesQuery from 'gigshq/graphql/queries/web-venues';
import managedVenuesQuery from 'gigshq/graphql/queries/managed-venues';

export default Service.extend({
  apollo: service(),
  accountManager: service('managers/account-manager'),
  favouriteVenuesManager: service('managers/favourite-venues-manager'),
  eventsFetcher: service('fetchers/events-fetcher'),
  sessionFetcher: service('fetchers/session-fetcher'),
  sessionStorage: service('session-storage'),

  searchedVenues: [],
  fetchedVenues: [],
  venuesMapBounds: null,
  isLoadingVenues: false,
  lastFetchedCoordinates: null,

  searchVenues(searchQuery, byAddress) {
    const variables = {
      filters: []
    };

    if (byAddress) {
      variables.filters.push({
        scope: 'within',
        arguments: [
          0.5, // within 500m
          { origin: [ searchQuery.latitude, searchQuery.longitude ] }
        ]
      });
    } else {
      variables.filters.push({
        scope: 'name_search',
        arguments: searchQuery,
      });
    }

    return this._makeVenuesFetchCall(variables, true);
  },

  clearSearchVenues() {
    this.set('searchedVenues', null);
  },

  fetchVenues(venueIds) {
    const variables = {
      filters: [{
        scope: 'venue_ids',
        arguments: [ venueIds ]
      }]
    };

    return this._makeVenuesFetchCall(variables);
  },

  fetchVenuesInBounds(mapBounds) {
    if (mapBounds == null) {
      // if map bounds is null, it's because they zoomed out too much
      this.set('fetchedVenues', []);
      return this.get('fetchedVenues');
    }

    const searchCoordinates = [
      [ mapBounds.getSouthWest().lat(), mapBounds.getSouthWest().lng() ],
      [ mapBounds.getNorthEast().lat(), mapBounds.getNorthEast().lng() ]
    ];

    const variables = {
      filters: [{
        scope: 'in_bounds',
        arguments: [
          searchCoordinates
        ]
      }]
    };

    return this._makeVenuesFetchCall(variables, false, searchCoordinates);
  },

  searchManagedVenues(searchQuery, isVenueListing = false) {
    const query = managedVenuesQuery;
    const variables = { searchQuery, isVenueListing };
    return this.apollo.queryOperation()({ query, variables }, 'viewer');
  },

  async _makeVenuesFetchCall(variables, searchOnly, coordinates) {
    if (coordinates == null || this.get('lastFetchedCoordinates') != coordinates.toString()) {
      this.set('isLoadingVenues', true);

      const query = venuesQuery(this.sessionFetcher.determineUserRole());

      variables.favouriteVenues = this.favouriteVenuesManager.favouriteVenueIds.map(venueId => parseInt(venueId, 10));

      const viewer = await this.apollo.queryOperation()({ query, variables }, 'viewer');

      this.accountManager.setAccount(viewer);
      this.set('isLoadingVenues', false);

      if (coordinates)
        this.set('lastFetchedCoordinates', coordinates.toString());

      // don't set the fetchedVenues if we're only conducting searches
      if (searchOnly)
        this.set('searchedVenues', viewer.publicVenues);
      else {
        this.set('fetchedVenues', viewer.publicVenues);

        viewer.publicVenues
          .forEach((venue) => this.eventsFetcher.addUpcomingEvents(venue.upcomingEvents));
      }
    }

    return this.get(searchOnly ? 'searchedVenues' : 'fetchedVenues');
  }
});

