import { inject as service } from '@ember/service';
import ReduxContainer from 'gigshq/pods/components/gigs-redux-container/component';
import { PropTypes as T } from 'ember-prop-types';
import { connect } from 'ember-redux';

// Actions
import {
  saveVenue,
  resetForm,
  setFormValue,
  setFormSocialProfileValue,
  markFormAsUploading,
  deleteImage
} from 'gigshq/actions/venues';

// Queries
import venueImageQuery from 'gigshq/graphql/queries/venue-images';

const mapStateToComputed = state => {
  const {
    venues: {
      venueEdition: {
        isDirty,
        isPending,
        data,
        errors,
        form: {
          id,
          name,
          description,
          streetAddress,
          city,
          country,
          postalCode,
          formattedAddress,
          profileImage,
          phoneNumber,
          email,
          latitude,
          longitude,
          capacityCount,
          venueCategoryId,
          socialProfile,
          openingHours,
          fbEventsUrl
        }
      }
    }
  } = state;

  return {
    isDirty,
    isPending,
    data,
    errors,
    id,
    name,
    description,
    streetAddress,
    city,
    country,
    postalCode,
    formattedAddress,
    phoneNumber,
    email,
    latitude,
    longitude,
    profileImage,
    capacityCount,
    venueCategoryId,
    socialProfile,
    openingHours,
    fbEventsUrl
  };
};

const mapDispatchToActions = dispatch => ({
  saveVenue: (mutate, venue) =>
    dispatch(saveVenue({form: 'venueEdition', mutate, venue})),
  resetForm: () => dispatch(resetForm({form: 'venueEdition'})),
  setFormValue: (name, value) =>
    dispatch(setFormValue({form: 'venueEdition', name, value})),
  setFormSocialProfileValue: (name, value) =>
    dispatch(setFormSocialProfileValue({form: 'venueEdition', name, value})),
  markFormAsUploading: isUploading =>
    dispatch(markFormAsUploading({form: 'venueEdition', isUploading})),
  deleteImage: (mutate, imageId) => dispatch(deleteImage({mutate, imageId}))
});

const VenueEditionForm = ReduxContainer.extend({
  propTypes: {
    venueCategories: T.array.isRequired
  },

  apollo: service(),

  didInsertElement() {
    const watchQuery = this.apollo.watchQueryOperation({
      query: venueImageQuery,
      variables: {venueId: this.id},
      fetchPolicy: 'network-only'
    });

    const imageQuerySubscription = watchQuery.subscribe({
      next: result => {
        this.set('images', result.data.viewer.venue.images.reverse());
      }
    });

    this.set('imageQuerySubscription', imageQuerySubscription);
  },

  willDestroyElement() {
    this.get('actions.resetForm')();

    this.imageQuerySubscription.unsubscribe();
    this.set('imageQuerySubscription', null);
  },

  actions: {
    save() {
      const variables = this.getProperties(
        'id',
        'name',
        'description',
        'streetAddress',
        'city',
        'country',
        'postalCode',
        'formattedAddress',
        'phoneNumber',
        'email',
        'latitude',
        'longitude',
        'capacityCount',
        'venueCategoryId',
        'socialProfile',
        'openingHours',
        'fbEventsUrl'
      );
      variables.profileImageId = this.get('profileImage.id');

      this.get('actions.saveVenue')(this.apollo.mutationOperation(), variables);
    },

    deleteVenueImage(imageId) {
      return this.get('actions.deleteImage')(
        this.apollo.mutationOperation(),
        imageId
      );
    }
  }
});

export default connect(
  mapStateToComputed,
  mapDispatchToActions
)(VenueEditionForm);
