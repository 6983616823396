import { reads, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

import transferVenueOwnership from 'gigshq/graphql/mutations/transfer-venue-ownership';

export default Component.extend(Localizable(translations), {
  internalValue: reads('value'),
  apollo: service(),
  flashMessages: service(),
  router: service(),
  venuesFetcher: service('fetchers/venues-fetcher'),
  duplicateVenues: [],
  foundDuplicateVenues: notEmpty('duplicateVenues'),

  checkForDuplicateVenues: task(function*(query) {
    this.set('isSearchingVenues', true);
    this.set('duplicateVenues', []);
    yield this.venuesFetcher.searchVenues(query, true);
    this.set('duplicateVenues', this.venuesFetcher.searchedVenues);
    this.set('isSearchingVenues', false);
  }).restartable(),

  requestTransferVenueOwnership: task(function*(venueId) {
    const mutation = transferVenueOwnership;
    const variables = {venueId};

    try {
      yield this.apollo.mutationOperation()({mutation, variables}, 'transferVenueOwnership');

      this.flashMessages.success(this.localTranslation('transfer.success'));
      this.router.transitionTo('app.venues');
    } catch (errors) {
      const message = this.localTranslation(
        `transfer.errors.${errors[0].messages[0]}`
      );
      this.flashMessages.error(message);
    }
  }).drop(),

  init() {
    this._super(...arguments);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  },

  didInsertElement() {
    const input = this.$('.google-places-autocomplete-input input').get(0);

    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: [ 'address' ]
    });

    const placeListener = window.google.maps.event.addListener(
      autocomplete,
      'place_changed',
      this.onPlaceChanged
    );

    this.setProperties({ autocomplete, placeListener, input });
  },

  willDestroyElement() {
    this.placeListener.remove();
  },

  onPlaceChanged() {
    const RELEVANT_ADDRESS_COMPONENT_TYPES = [
      'street_number',
      'route',
      'locality',
      'country',
      'postal_code',
      'sublocality_level_1'
    ];

    const {
      address_components: addressComponents,
      geometry: { location }
    } = this.autocomplete.getPlace();

    const relevantComponents = addressComponents
      .filter(component =>
        RELEVANT_ADDRESS_COMPONENT_TYPES.includes(component.types[0])
      )
      .reduce(
        (addressObject, component) => ({
          ...addressObject,
          [component.types[0]]: component.long_name
        }),
        {}
      );

    const city = relevantComponents.locality
      ? relevantComponents.locality
      : relevantComponents.sublocality_level_1;

    const address = {
      streetAddress: `${relevantComponents.street_number} ${
        relevantComponents.route
      }`,
      city,
      country: relevantComponents.country,
      postalCode: relevantComponents.postal_code,
      latitude: parseFloat(location.lat()),
      longitude: parseFloat(location.lng()),
      formattedAddress: this.input.value
    };

    this.checkForDuplicateVenues.perform({ latitude: location.lat(), longitude: location.lng() });
    this.update(address);
  },

  actions: {
    setValue(formattedAddress) {
      this.set('internalValue', formattedAddress);
      this.update({});
    },

    requestTransfer(venueId) {
      if (window.confirm(this.localTranslation('transfer.confirm')))
        this.requestTransferVenueOwnership.perform(venueId);
    },

  }
});
