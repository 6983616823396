import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import { USER_TYPE_MEMBER, USER_TYPE_TENANT, USER_TYPE_GENERAL, USER_TYPE_AFFILIATE } from 'gigshq/constants/user-types';

export default Component.extend({
  isAboutMember: equal('userType', USER_TYPE_MEMBER),
  isAboutTenant: equal('userType', USER_TYPE_TENANT),
  isAboutAffiliates: equal('userType', USER_TYPE_AFFILIATE),
  isAboutGeneral: equal('userType', USER_TYPE_GENERAL)
});
