import { computed } from '@ember/object';
import Component from '@ember/component';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  offerImageUrl: computed(function() {
    if (this.get('i18n.locale') === 'en')
      return `/assets/images/venue-offers/10-for-20-en.jpg`;
    else
      return `/assets/images/venue-offers/10-for-20-fr.jpg`;
  }),
});
